.theme-base-08 {
	.sidebar {
		background-color: #ac4142;
	}
	.content a {
		color: #ac4142;
	}
	.related-posts li a:hover {
		color: #ac4142;
	}
}
.theme-base-09 {
	.sidebar {
		background-color: #d28445;
	}
	.content a {
		color: #d28445;
	}
	.related-posts li a:hover {
		color: #d28445;
	}
}
.theme-base-0a {
	.sidebar {
		background-color: #f4bf75;
	}
	.content a {
		color: #f4bf75;
	}
	.related-posts li a:hover {
		color: #f4bf75;
	}
}


.theme-base-custom {
	.sidebar {
		background-image: url('/img/speckle.png');
		background-repeat: repeat;
		// --fleck-seed: 123449;
		// --fleck-count: 580;
		// --fleck-size-base: 4px;
		--fleck-color-1: #0193c1;
		--fleck-color-2: #74d8e6;
		--fleck-color-3: #0bc0e0;
		--fleck-color-4: #3ddaf5;
		--fleck-color-5: #e2f9fe;
		--fleck-color-6: #02313f;
		--background-color: #300030;
	}
	.content a {
		color: var(--fleck-color-6);
	}
	.related-posts li a:hover {
		color: #02313f;
	}
	.badge-category {
		background-color: #300030;
	}
	.navigation a {
		border-color: #300030;
	}
	.sidebar a:hover {
		color: #84dce2;
	}

	.sidebar-about, .sidebar-nav {
		background-color: #300030;
		padding: 4px;
	}
}
.theme-base-0b {
	.sidebar {
		background-color: #90a959;
	}
	.content a {
		color: #90a959;
	}
	.related-posts li a:hover {
		color: #90a959;
	}
}
.theme-base-0c {
	.sidebar {
		background-color: #75b5aa;
	}
	.content a {
		color: #75b5aa;
	}
	.related-posts li a:hover {
		color: #75b5aa;
	}
}
.theme-base-0d {
	.sidebar {
		background-color: #6a9fb5;
	}
	.content a {
		color: #6a9fb5;
	}
	.related-posts li a:hover {
		color: #6a9fb5;
	}
}
.theme-base-0e {
	.sidebar {
		background-color: #aa759f;
	}
	.content a {
		color: #aa759f;
	}
	.related-posts li a:hover {
		color: #aa759f;
	}
}
.theme-base-0f {
	.sidebar {
		background-color: #8f5536;
	}
	.content a {
		color: #8f5536;
	}
	.related-posts li a:hover {
		color: #8f5536;
	}
}