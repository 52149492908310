// for the list of posts

.section__title {
  font-size: $section__title-font-size;
}

.post-list__item {
  margin-bottom: 3em;
}

.item__title--big {
  display: block;
  font-size: $item__title-big-font-size;
  line-height: 1.25;
}

.item__title--small {
  font-size: 1rem;
}

.item__date {
  color: $item__date-color;
  display: block;
  font-size: $meta-font-size;
  margin-bottom: 0.2rem;
  margin-top: 0.2rem;
}

.item__metadata {
  display: flex;
  flex-direction: row;
  align-items: baseline;
}

.item__metadata > span {
  margin-right: 4px;
}
